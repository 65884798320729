
.scene {
  width: 100%;
  perspective: 600px;
  flex-grow:1;
  height:100%;
  user-select: none;
  padding:0 0 13px 0;
}

@media only screen and (max-width: 960px) {
  #creator {
    .scene {
      height:100%;
     
      figure, section {
        font-size:1.5em;
        padding:0.5em;
        margin:0;
      }
    }
  }
}

.full-height{
  height:100%;
}

.edit .scene{
  height:50vh;
}

.small .scene {
  height:40vh;
  width: 90%; 
  margin: 1.5em auto 0 auto;

  figure, section {
    font-size:1.2em;
    padding:10%;
    margin:0;
  }
}

.edit {
  figure, section {
    font-size:1.4em;
  }
}

.edit #create, .preview #create {
  display:none;
}

#share {
  margin-right: 2rem;
}

#share, #edit {
  display:none;
}

.preview #share, .preview #edit {
  display:block;
}

.preview #preview {
  display:none;
}

#create {
  margin-top:1em;
}

.card {
  font-family: 'Great Vibes', cursive;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: rotateX(0deg);
  -webkit-tap-highlight-color: transparent;
}

.front {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  -webkit-perspective: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0.4em 0.5em;
  box-sizing: border-box;
}


.back {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  transform: rotateY( 180deg );
  // -webkit-perspective: 0;
  // -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:0.4em 0.5em;
  box-sizing: border-box;
}

.front-cover .front {
  /* background: red; */
}

.front-cover .back {
  background: #efefef;
  color:#111;
  background-image: linear-gradient(#fff, #efefef);
  width:100%;
  height:100%;

  .photo {
    height:90%;
  }
}

.back-cover .front {
  background: #efefef;
  color:#111;
  border-left:1px solid #ccc;
  background-image: linear-gradient(#fff, #efefef);
  width:100%;
  height:100%;
  overflow-y:scroll;
  word-wrap: break-word;
  align-items: flex-start;
}

.back-cover .back {
  /* background: pink; */
}

.front-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 1px 1px 5px #444;
  border-radius:2px;
}

.back-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transform: rotateY(45deg);
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 1px 1px 5px #444;
  border-radius:2px;
}

.show-front-inside {
  transform: rotateX(0deg) rotateY(-180deg);
}

.show-front-inside .back-cover {
 transform: rotateY(135deg);
}

.show-back-inside {
  transform: rotateX(0deg) rotateY(-180deg) translateX(100%);
}

.show-back-inside .front-cover {
  transform: rotateY(45deg);
 }

.show-back-inside .back-cover {
 transform: rotateY(180deg);
}

.show-back-cover {
  transform: rotateX(0deg) rotateY(-180deg);
}

.show-back-cover .front-cover {
  transform: rotateY(-0deg);
}

.show-back-cover .back-cover {
 transform: rotateY(0deg);
}
