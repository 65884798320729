
.margin-top {
 margin-top:1em;
}

.video-wrapper {
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-wrapper {
  overflow-x:scroll;
  border-bottom:1px solid #828282;
  background:#ececec;
  margin-top:0.5em;
  padding: 0  0.9em  0.9em  0.9em;
}

.select-cover, .search-results { 
  min-height:90px;
  overflow-y:scroll;
  border-top:none;
  height:205px;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .select-cover  {
    height:130px;
  }
  .search-results {
    height:100px;
  }
}

.pick-cover, .pick-gif {
  width:100%;
  height:100%;
  min-height:85px;
  display:block;
  border-radius: 3px;
  // box-shadow: 1px 1px 5px #222;
  border:1px solid #ccc;
  box-sizing: border-box;
  flex-shrink:0;
  box-sizing: border-box;
}


.selected {
  border:3px solid #111;
}

